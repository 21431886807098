@import '../../styles/shared';

.menu {
  display: flex;
  flex-direction: column;

  width: 240px;
  padding: 20px 0;

  background-color: var(--background-base-transparent-100);

  @media print {
    display: none;
  }
}

.logo__wrapper {
  align-self: center;

  margin-bottom: 44px;
}

.menu .logo {
  height: 48px;
}

.profile {
  display: flex;
  align-items: center;

  padding: 0 40px;
}

.user__name {
  @extend %text-ellipsis;

  padding: 0;
  margin-right: 5px;

  color: var(--color-base-transparent-64);
}

.link__logout {
  flex-shrink: 0;

  color: var(--color-actions-transparent-48);
  transition: color $transition-base;

  &:hover {
    color: var(--color-actions);
  }
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 55px;
}

.btn {
  width: 160px;
  height: 48px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.navigation {
  margin-bottom: auto;
}

.navigation__menu {
  list-style: none;

  padding: 0;
  margin: 0;
}

.link {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  height: 60px;
  padding: 0 40px;

  color: var(--color-base-transparent-64);
  font-size: 16px;

  &__active {
    background-color: var(--color-default);

    color: var(--color-base);

    &::after {
      content: '';

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      width: 8px;

      border-radius: 4px 0 0 4px;
      box-shadow: -4px 0 8px 0 rgba(var(--color-primary-rgb), 0.5);
      background-color: var(--color-primary);
    }
  }
}

.link__icon {
  margin-right: 10px;
}

.link__text {
  font-weight: normal;
  white-space: nowrap;
}
