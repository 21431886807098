@import '../../styles/shared';

.field {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  height: 40px;
  padding-left: 8px;
  padding-right: 32px;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-base);
  border: 1px solid var(--color-base-transparent-40);
  border-radius: 4px;
  outline: none;

  &:hover {
    cursor: pointer;
    border: 1px solid var(--color-base-transparent-64);
  }

  &:focus,
  &[aria-expanded='true'] {
    border-color: var(--color-primary);
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);

  path {
    fill: var(--color-actions);
  }
}

.value {
  @extend %text-ellipsis;
}

.options {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
}

.additionalOption {
  padding: 8px 12px;
  cursor: pointer;
}

.option {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--color-base);
  box-shadow: $box-shadow-line-bottom;
}

.checkboxLabel {
  width: 100%;
  padding: 8px 12px;
}

.checkboxIcon {
  margin-right: 12px;
}
