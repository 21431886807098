.calendar {
  position: relative;

  height: 40px;

  display: inline-flex;
}

.btn__date {
  flex-grow: 1;

  color: inherit;

  span {
    color: inherit;
  }
}

.calendar__wrapper {
  position: absolute;
  top: 105%;
  left: 0;
  z-index: 99;

  box-shadow: 0 8px 16px 0 #00000029;
  background-color: var(--color-default);
  border-radius: 4px;

  width: 288px;
}

.react__calendar {
  padding: 12px 16px;

  button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;
  }

  abbr {
    text-decoration: none;
  }

  :global(.react-calendar) {
    &__month-view {
      font-size: 12px;
      line-height: 14px;
    }

    &__navigation {
      padding: 0;
      margin-bottom: 8px;
    }

    &__navigation__label {
      color: var(--color-base);
      font-size: 14px;
      line-height: 17px;
    }

    &__navigation__arrow {
      font-size: 16px;
      padding: 1px 18px;
    }

    &__month-view__weekdays__weekday {
      color: #b6c4ca;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    }

    &__month-view__days__day:hover {
      background-color: var(--color-primary-transparent-20);
    }

    &__tile {
      background: transparent;

      cursor: pointer;

      padding: 5px;
      margin: 0;

      abbr {
        position: relative;
      }

      &--active,
      &--hasActive {
        position: relative;
        color: #fff;

        &:before {
          content: '';

          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          z-index: -1;

          background: var(--color-primary);
        }
      }
    }

    &__month-view__weekNumbers {
      button {
        font-weight: 500;
        margin-right: 24px;
      }
    }
  }
}

.calendar__periods {
  display: flex;
  flex-wrap: wrap;

  padding: 12px 16px;
}

.period {
  margin: 0 8px 8px 0;

  label {
    overflow: hidden;
    border: 1px solid var(--color-actions);
    padding: 12px 16px;

    border-radius: 4px;
  }
}

.icon {
  & * {
    fill: var(--color-actions);
  }
}
