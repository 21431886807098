$break-mobile: 812px;
$break-tablet: 959px;
$break-laptop: 1366px;
$break-desktop: 1440px;

$box-shadow-line-bottom: 0 1px 0 0 var(--color-base-transparent-10);
$box-shadow-line-top: 0 -1px 0 0 var(--color-base-transparent-10);

$transition-base: 0.15s ease-in-out;

@mixin respond-to($media, $orientation: null) {
  @if $media == mobile {
    @if $orientation == landscape {
      @media only screen and (max-width: $break-mobile) and (orientation: landscape) {
        @content;
      }
    }
    @if $orientation == portrait {
      @media only screen and (max-width: $break-mobile) and (orientation: portrait) {
        @content;
      }
    }
    @if $orientation == null {
      @media only screen and (max-width: $break-mobile) {
        @content;
      }
    }
  } @else if $media == tablet {
    @media only screen and (max-width: $break-tablet) {
      @content;
    }
  } @else if $media == laptop {
    @media only screen and (max-width: $break-laptop) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $break-desktop) {
      @content;
    }
  }
}

@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

@mixin line-clamp($lines-to-show) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
  overflow: hidden;
}

%center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%full-screen {
  width: 100vw;
  height: 100vh;
}

%text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

%scrollbar {
  &::-webkit-scrollbar {
    position: relative;
    right: -8px;

    height: 8px;
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(var(--color-base-rgb), 0.2);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(var(--color-base-rgb), 0.3);
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: rgba(var(--color-base-rgb), 0.4);
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(var(--color-base-rgb), 0.3) transparent;
}

%admin-content--tabs {
  display: flex;
  flex-direction: column;

  height: 100vh;
  overflow: auto;
  flex-grow: 1;
  min-width: 780px;

  padding: 24px 40px;

  @media print {
    padding: 0;
    height: auto;
    flex-grow: unset;
    overflow: visible;
  }
}

.admin-header--breadcrumbs {
  padding: 24px 40px;

  box-shadow: 0 1px 0 0 var(--color-base-transparent-40);
}

.flex {
  display: flex;
}

%input-datepicker {
  width: 100%;
}

%input-datepicker-button {
  border: 1px solid var(--color-base-transparent-40);

  border-radius: 4px;
  color: var(--color-base);

  &:hover {
    border-color: var(--color-base-transparent-64);
  }

  &:focus {
    border-color: var(--color-primary);
  }
}
