.form__field {
  position: relative;
}

.label {
  display: block;

  color: var(--color-base-transparent-64);
  font-size: 14px;
  font-weight: normal;

  margin-bottom: 8px;

  height: 17px;
}

.error {
  position: absolute;
  top: 95%;

  padding: 4px 0;

  font-size: 12px;
  color: var(--color-error);
  width: 100%;
  display: flex;
  flex-direction: column;
  & > * {
    width: calc(100% + 20px);
  }
}
