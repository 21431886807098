@import '../../../../styles/shared';

.timerange__wrapper {
  :global {
    .react-timerange-picker {
      display: inline-flex;
      position: relative;

      color: var(--color-base);
      font-size: 14px;

      transition: $transition-base;

      &:hover {
        .react-timerange-picker__wrapper {
          border-color: var(--color-base-transparent-64);
        }

        .react-timerange-picker__range-divider {
          box-shadow: 1px 0 0 0 var(--color-base-transparent-64);
        }
      }
    }
    .react-timerange-picker--invalid {
      .react-timerange-picker__wrapper {
        border-color: var(--color-error) !important;
      }

      .react-timerange-picker__range-divider {
        box-shadow: 1px 0 0 0 var(--color-error) !important;
      }
    }
    .react-timerange-picker--disabled,
    .react-timerange-picker--disabled:hover {
      color: var(--color-base-transparent-56);
      background-color: var(--color-base-transparent-05);

      .react-timerange-picker__wrapper {
        border-color: var(--color-base-transparent-10);
      }

      .react-timerange-picker__range-divider {
        box-shadow: 1px 0 0 0 var(--color-base-transparent-10);
      }

      .react-timerange-picker__inputGroup__input {
        color: var(--color-base-transparent-56);
      }
    }
    .react-timerange-picker__wrapper {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      align-items: center;
      width: 180px;
      border: 1px solid var(--color-base-transparent-40);
      border-radius: 4px;

      transition: border-color $transition-base;

      &:focus-within:hover,
      &:focus-within {
        border-color: var(--color-primary);

        .react-timerange-picker__range-divider {
          box-shadow: 1px 0 0 0 var(--color-primary);
        }
      }
    }
    .react-timerange-picker__inputGroup {
      height: 40px;
      flex-grow: 1;
      padding: 8px 24px 8px 12px;
    }
    .react-time-picker__inputGroup__divider {
      white-space: pre;
      padding: 0 1px;
    }
    .react-timerange-picker__inputGroup__input {
      height: 100%;
      position: relative;
      border: 0;
      padding: 0;
      background: none;
      font-size: 14px;
      color: var(--color-base);
      outline: none;
    }
    .react-timerange-picker__inputGroup__input::-webkit-outer-spin-button,
    .react-timerange-picker__inputGroup__input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
    .react-timerange-picker__range-divider {
      height: 100%;
      width: 1px;
      box-shadow: 1px 0 0 0 var(--color-base-transparent-40);

      transition: box-shadow $transition-base;
    }
    .react-timerange-picker__inputGroup__leadingZero {
      user-select: none;
    }

    .timerange__expended {
      width: 100%;

      .react-timerange-picker__wrapper {
        border: none;

        .react-timerange-picker__range-divider {
          width: 16px;
          text-align: center;
          line-height: 40px;
          border: none;
          box-shadow: none !important;
        }

        .react-timerange-picker__inputGroup {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--color-base-transparent-40);
          border-radius: 4px;
          width: calc(50% - 10px);

          input,
          span {
            font-size: 14px;
            line-height: 17px;
            height: 17px;
          }
        }
      }
    }
  }
}
