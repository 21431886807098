@import '../../../styles/shared';

.modal :global {
  .rc-dialog-close {
    display: none;
  }

  .rc-dialog-body {
    position: relative;

    width: 600px;
    height: 540px;
  }
}

.modal.full__mode :global(.rc-dialog-body::after) {
  content: '';

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(#eff0ef, 0.7);
}

.day__panel {
  display: flex;
  flex-direction: column;

  flex-shrink: 0;
  height: 100%;
  width: 296px;
}

.line__title {
  word-break: break-word;
  color: var(--color-base-transparent-80);
  font-size: 16px;

  padding: 22px 40px 22px 24px;
  margin-bottom: 24px;
}

.day__title {
  color: var(--color-base-transparent-80);
  font-size: 14px;
}

.day__item {
  font-size: 12px;
  color: var(--color-base);

  padding: 8px 0;

  :first-child {
    line-height: 16px;

    word-break: break-word;
  }
}

.weekdays,
.btn__wrapper {
  display: flex;
  align-items: center;
}

.weekdays {
  justify-content: space-between;
}

.btn__wrapper {
  border-top: 1px solid rgba(#bfc5d2, 0.5);

  padding: 24px;
}

.weekdays {
  padding: 0 24px;
  height: 50px;
}

.weekdays__full {
  padding: 0;
  height: auto;
  margin-bottom: auto;
}

.weekday {
  font-size: 16px;
  color: var(--color-base);
}

.weekdays__list {
  width: 100%;
}

.weekday__long {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 100%;

  cursor: pointer;
  background-color: var(--color-default);

  &:hover {
    background-color: #e6e6e6;
  }
}

.day {
  @extend %scrollbar;

  border-top: 1px solid rgba(#bfc5d2, 0.5);

  padding: 16px 24px;
  margin-bottom: auto;
  overflow: auto;
}

.btn {
  padding: 12px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}

.btn__wrapper.full__mode::after {
  background-color: rgba(#fff, 0.7);
}
