.filterModal {
  .filterModal__title {
    padding: 0 50px 20px 0;
    border: none;
  }

  .rc-dialog-body {
    margin: 20px;
    width: 400px;
    height: auto;
  }

  .filterModal__field {
    margin-bottom: 20px;

    label {
      display: block;
      color: var(--color-base-transparent-64);
      font-size: 14px;
      margin-bottom: 8px;
      height: 17px;
    }

    .filterModal__select {
      width: 100%;
      height: 100%;
    }

    .select__menu {
      border: 1px solid var(--color-base-transparent-80);
    }

    .select__single-value {
      overflow: visible;
    }
  }

  .filterModal__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    .filterModal__button {
      padding: 12px 18px;
      width: 48%;
    }
  }
}
