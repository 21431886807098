@import '../../../styles/shared';
@import '../../../styles/variables';

.modal :global {
  .rc-dialog-body {
    width: 450px;
    height: 680px;
    display: flex;
    flex-direction: column;

    @include respond-to(mobile) {
      width: 300px;
    }
  }
}

.modal__header {
  padding: 18px 40px;
  color: var(--color-base-transparent-80);
  box-shadow: $box-shadow-line-bottom;
}

.tags {
  display: flex;
  flex-wrap: wrap;

  flex-shrink: 0;
  height: 100px;
  padding: 12px 40px;

  box-shadow: $box-shadow-line-bottom;
  overflow: auto;
  @extend %scrollbar;
}

.tags__text {
  align-self: flex-end;
  margin-bottom: 27px;
  color: var(--color-base-transparent-56);
}

.tag {
  display: flex;
  @extend %center;

  height: 24px;
  font-size: 14px;
  background-color: var(--color-primary);
  color: var(--color-default);
  border-radius: 4px;

  padding: 5px 10px;
  max-width: 270px;

  margin-bottom: 8px;

  &:not(:last-of-type) {
    margin-right: 8px;
  }
}

.tag__icon {
  margin-left: 10px;
  filter: grayscale(1);
}

.tag__name {
  margin: 0 10px;

  @extend %text-ellipsis;
}

.tag__btn {
  color: var(--color-default);
}

.tags__list {
  padding: 0 40px;
  overflow: auto;
  flex-grow: 1;

  color: var(--color-base-transparent-32);

  @extend %scrollbar;

  & .tag__name:hover {
    overflow: visible;
    white-space: normal;
    height: auto;
    word-break: break-word;
  }
}

.tags__item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  font-size: 14px;
  color: var(--color-actions);
}

.tags__item__align {
  margin-left: 20px;
}

.tags__item__hidden {
  display: none;
}

.checkbox {
  @extend %text-ellipsis;
}

.checkbox input:checked {
  & ~ .tag__name {
    color: var(--color-primary);
  }

  & ~ .tag__icon {
    filter: grayscale(0);
  }
}

.tag__time {
  opacity: 0.5;
}

.controls {
  box-shadow: $box-shadow-line-top;
  padding: 24px;
}

.btn {
  padding: 12px 16px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}

.btn__select__all {
  width: calc(50% - 8px);
  height: 40px;
  margin-bottom: 24px;
}

.search__field {
  flex-grow: 1;
}

.search__block {
  position: relative;

  width: 100%;

  padding: 24px 40px;
  margin-bottom: 24px;
}

.search__action {
  margin-right: 16px;
  color: var(--color-primary);
}

.radio__block {
  margin: 0 8px 8px 16px;

  label {
    & * {
      width: calc(100% - 40px);
      word-break: break-all;
      white-space: pre-wrap;
      hyphens: auto;
    }
  }
}

.note {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: #747b8b;
  &__title:hover + .hint {
    display: block;
  }
}

.editMode__controls {
  display: flex;
  align-items: center;

  padding: 0 40px;
  margin-bottom: 20px;

  color: var(--color-base-transparent-64);

  &.editMode__active {
    justify-content: space-between;
  }
}

.editMode__btn {
  margin-left: 8px;
  color: var(--color-primary);
}

.btn__delete {
  width: 24px;
  height: 24px;
  opacity: 0;
  visibility: hidden;
  font-size: 20px;

  &.editMode__active {
    opacity: 0.48;
    visibility: visible;
  }

  &:hover {
    opacity: 1;
  }
}

.tags__group_icons {
  display: flex;
  align-items: center;

  .tags__count {
    width: 16px;
    height: 16px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: var(--color-default);
    background-color: var(--color-primary);
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    margin-right: 8px;
  }
}
