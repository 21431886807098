@import 'src/styles/shared';

.select {
  color: var(--color-base);
  font-size: 14px;

  &__invalid :global(.select__control) {
    border-color: var(--color-error);
  }

  :global(.select) {
    &__control {
      height: 40px;
      border-color: var(--color-base-transparent-40);

      &:hover {
        border-color: var(--color-base-transparent-64);
      }
    }

    &__control--is-focused {
      box-shadow: none;
      border-color: var(--color-primary) !important;
    }

    &__placeholder {
      color: var(--color-base-transparent-56);
    }

    &__indicator-separator {
      background-color: transparent;
    }

    &__input {
      color: var(--color-default);
    }

    &__option {
      background-color: transparent !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        overflow: visible;
        white-space: normal;
        height: auto;
        width: auto;
        word-break: break-word;
      }
    }

    &__option--is-selected {
      color: #333333;
      background-color: #e6e6ec !important;
    }

    &__option--is-focused {
      background-color: #f7f7f7 !important;
    }

    &__single-value {
      color: inherit;

      overflow: visible;
    }

    &__indicators {
      font-size: 24px;
      color: var(--color-default);
    }

    &__menu {
      left: 1px;
      right: 1px;

      width: auto;
    }
  }
}
